<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Edit Interview</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col
            cols="10"
            offset="1"
          >
            <b-row class="mt-1">
              <b-col cols="6">
                <b-form-group
                  label="Name"
                  label-cols-md="4"
                  class="text-right"
                >
                  <span
                    class="form-control text-left"
                    v-text="addInterviewer.name"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Email Address"
                  label-cols-md="4"
                  class="text-right"
                >
                  <span
                    class="form-control text-left"
                    v-text="addInterviewer.email"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Phone Number"
                  label-cols-md="4"
                  class="text-right"
                >
                  <span
                    class="form-control text-left"
                    v-text="addInterviewer.phone"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Status"
                  label-cols-md="4"
                  class="text-right"
                >
                  <span
                    class="form-control text-left"
                    v-text="addInterviewer.status"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <hr class="mt-0">
            <div class="d-flex ml-4">
              <h4 class="mb-2">
                Time Slots
              </h4>
            </div>
            <b-row>
              <b-col cols="6">
                <b-form-group
                  label="Start Date"
                  label-cols-md="4"
                  class="text-right"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Start Date"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="start_date"
                      class="form-control"
                      :config="startDateConfig"
                      placeholder="MM-DD-YYYY"
                      @on-change="availableStartDateChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="End Date"
                  label-cols-md="4"
                  class="text-right"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="End Date"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="end_date"
                      class="form-control"
                      :config="endDateConfig"
                      placeholder="MM-DD-YYYY"
                      @on-change="availableEndDateChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group
                  label="Timings From"
                  label-cols-md="4"
                  class="text-right"
                >
                  <b-row>
                    <b-col cols="5">
                      <validation-provider
                        #default="{ errors }"
                        name="Timings From"
                        rules="required"
                      >
                        <flat-pickr
                          v-model="addInterviewer.available_start_time"
                          class="form-control"
                          :config="fromTimeConfig"
                          placeholder="00:00"
                          @on-change="availableStartTimeChanged"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col cols="7">
                      <b-form-group
                        label="To"
                        label-cols-md="3"
                        class="text-right mb-0"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="To"
                          rules="required"
                        >
                          <flat-pickr
                            v-model="addInterviewer.available_end_time"
                            class="form-control"
                            :config="toTimeConfig"
                            placeholder="00:00"
                            @on-change="availableEndTimeChanged"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Duration"
                  label-cols-md="4"
                  class="text-right"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Duration"
                    rules="required"
                  >
                    <v-select
                      v-model="addInterviewer.duration_id"
                      :options="durations"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group
                  label="Recurring"
                  label-cols-md="4"
                  class="text-right"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Recurring"
                    rules="required"
                  >
                    <v-select
                      v-model="recurring"
                      :options="frequencies"
                      :reduce="item => item"
                      :clearable="false"
                      placeholder="Select"
                      @input="frequencyChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  v-show="showWeeks"
                  label="Week Days"
                  label-cols-md="4"
                  class="text-right"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Week Days"
                    :rules="showWeeks? 'required' : ''"
                  >
                    <v-select
                      v-model="addInterviewer.week_days_id"
                      :options="weekDays"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Block Time From"
                  label-cols-md="4"
                  class="text-right"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Timings From"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="addInterviewer.block_start_time"
                      class="form-control"
                      :config="blockFromTimeConfig"
                      placeholder="00:00"
                      @on-change="blockStartTimeChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="To"
                  label-cols-md="4"
                  class="text-right"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="To"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="addInterviewer.block_end_time"
                      class="form-control"
                      :config="blockToTimeConfig"
                      placeholder="00:00"
                      @on-change="blockEndTimeChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Weekends"
                  label-cols-md="4"
                  class="text-right"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Weekends"
                    rules="required"
                  >
                    <v-select
                      v-model="addInterviewer.weekend_ids"
                      :options="weekDays"
                      multiple
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Holidays"
                  label-cols-md="4"
                  class="text-right"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Holidays"
                    rules="required"
                  >
                    <v-select
                      v-model="addInterviewer.holiday_ids"
                      :options="holidays"
                      multiple
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <hr class="mt-0">
            <div class="d-flex ml-4">
              <h4 class="mb-2">
                Communication Mode Details
              </h4>
            </div>
            <div
              v-for="(communicationInfo, index) in addInterviewer.communications"
              :id="communicationInfo.id"
              :key="communicationInfo.id"
              class="d-flex"
            >
              <div class="left w-100">
                <b-row>
                  <b-col cols="6">
                    <b-form-group
                      label="Type"
                      label-cols-md="4"
                      class="text-right"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Type"
                        rules="required"
                      >
                        <v-select
                          v-model="communicationInfo.type_id"
                          :options="comTypes"
                          :reduce="item => item.id"
                          :clearable="false"
                          placeholder="Select"
                          @input="communicationTypeMatch(communicationInfo.type_id, index)"
                        />
                        <small
                          class="text-danger"
                          v-text="errors[0] !== undefined ? errors[0].replace('Type' + index, 'Type') : ''"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group
                      label="Value"
                      label-cols-md="4"
                      class="text-right"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Value"
                        rules="required"
                      >
                        <b-form-input
                          v-model="communicationInfo.value"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small
                          class="text-danger"
                          v-text="errors[0] !== undefined ? errors[0].replace('Value' + index, 'Value') : ''"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <div class="right ml-1">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="btn-icon rounded-circle"
                  @click="removeCommunication(index)"
                >
                  <feather-icon
                    icon="XIcon"
                  />
                </b-button>
              </div>
            </div>
            <div v-if="comTypes.length !== addInterviewer.communications.length">
              <div class="right ml-4">
                <b-button
                  variant="outline-primary"
                  class="btn-icon rounded-circle mr-1"
                  @click="addCommunication"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
                Add Communication
              </div>
            </div>

            <hr>
            <div class="d-flex ml-4">
              <h4 class="mb-2">
                Interviewer Skills
              </h4>
            </div>
            <div class="d-flex mt-2">
              <h5 class="mb-2 ml-4">
                Main Areas
              </h5>
            </div>
            <b-row>
              <b-col
                cols="11"
                offset="1"
              >
                <b-row>
                  <b-col
                    v-for="option in skills"
                    :key="option.id"
                    :value="option.id"
                    cols="12"
                    md="3"
                    class="col-xxl-2 mb-1"
                  >
                    <b-form-checkbox
                      :checked="technologySelected(option.id)"
                      @input="checked(option.id)"
                    >
                      {{ option.label }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <hr>
              </b-col>
            </b-row>

            <div class="d-flex mt-0">
              <h5 class="mb-2 ml-4">
                Vacation Dates
              </h5>
            </div>
            <div
              v-for="(vacationInfo, index) in addInterviewer.vacations"
              :id="index"
              :key="index"
              class="d-flex"
            >
              <div class="left w-100">
                <b-row>
                  <b-col cols="5">
                    <b-form-group
                      label="From"
                      label-cols-md="4"
                      class="text-right"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="From"
                        rules="required"
                      >
                        <flat-pickr
                          v-model="vacationInfo.vacation_start_date_text"
                          class="form-control"
                          :config="vacationConfigs[index].from"
                          placeholder="MM-DD-YYYY"
                          @on-change="startDateChanged(index)"
                        />
                        <small
                          class="text-danger"
                          v-text="errors[0] !== undefined ? errors[0].replace('From' + index, 'From') : ''"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="5">
                    <b-form-group
                      label="To"
                      label-cols-md="4"
                      class="text-right"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="To"
                        rules="required"
                      >
                        <flat-pickr
                          v-model="vacationInfo.vacation_end_date_text"
                          class="form-control"
                          :config="vacationConfigs[index].to"
                          placeholder="MM-DD-YYYY"
                          @on-change="endDateChanged(index)"
                        />
                        <small
                          class="text-danger"
                          v-text="errors[0] !== undefined ? errors[0].replace('To' + index, 'To') : ''"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-show="!vacationInfo.is_all_day"
                    cols="5"
                  >
                    <b-form-group
                      label="Duration From"
                      label-cols-md="4"
                      class="text-right"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Timings From"
                        :rules="vacationInfo.is_all_day ? '' : 'required'"
                      >
                        <flat-pickr
                          v-model="vacationInfo.vacation_start_time"
                          class="form-control"
                          :config="vacationConfigs[index].fromTime"
                          placeholder="00:00"
                          @on-change="startTimeChanged(index)"
                        />
                        <small
                          class="text-danger"
                          v-text="errors[0] !== undefined ? errors[0].replace('Timings From' + index, 'Timings From') : ''"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-show="!vacationInfo.is_all_day"
                    cols="5"
                  >
                    <b-form-group
                      label="Duration To"
                      label-cols-md="4"
                      class="text-right"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Timings To"
                        :rules="vacationInfo.is_all_day ? '' : 'required'"
                      >
                        <flat-pickr
                          v-model="vacationInfo.vacation_end_time"
                          class="form-control"
                          :config="vacationConfigs[index].toTime"
                          placeholder="00:00"
                          @on-change="endTimeChanged(index)"
                        />
                        <small
                          class="text-danger"
                          v-text="errors[0] !== undefined ? errors[0].replace('To' + index, 'To') : ''"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="2"
                    class="mt-50"
                  >
                    <b-form-checkbox
                      v-model="vacationInfo.is_all_day"
                      class="ml-4"
                    >
                      All Day
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="11"
                    offset="1"
                  >
                    <hr>
                  </b-col>
                </b-row>
              </div>
              <div class="right ml-1 mr-2">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="btn-icon rounded-circle"
                  @click="removeVacation(index)"
                >
                  <feather-icon
                    icon="XIcon"
                  />
                </b-button>
              </div>
            </div>
            <div class="right ml-4">
              <b-button
                variant="outline-primary"
                class="btn-icon rounded-circle mr-1"
                @click="addVacation"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
              Add Vacation
            </div>
            <hr>
            <b-row class="ml-2">
              <b-col class="text-right">
                <b-button
                  variant="primary"
                  type="button"
                  @click="submitForm"
                >
                  Update Interviewer
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormCheckbox, BButton,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
import Interviewers from '@/js/interviewers'
import Constants from '@/js/constant'
import LIST from '@/js/lists'
import moment from 'moment'

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    vSelect,
    flatPickr,
  },
  directives: {
  },
  data() {
    return {
      required,
      recurring: null,
      addInterviewer: {
        employee_id: null,
        status_id: null,
        name: '',
        email: '',
        end_date: null,
        start_date: null,
        duration_id: null,
        recurring_id: null,
        weekend_ids: null,
        block_start_time: null,
        block_end_time: null,
        weekdays_id: null,
        holiday_ids: null,
        skype_id: '',
        webex: '',
        zoom_info: '',
        microsoft_meetings: '',
        available_start_time: null,
        available_end_time: null,
        vacations: [],
        communications: [],
        skill_ids: [],
      },
      end_date: null,
      start_date: null,
      from_date: null,
      to_date: null,
      vacation_start_date: null,
      vacation_end_date: null,
      startDateConfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'm-d-Y',
        altInput: true,
        dateFormat: 'm-d-Y',
        minDate: new Date(2000, 1, 1),
      },
      endDateConfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'm-d-Y',
        altInput: true,
        dateFormat: 'm-d-Y',
        minDate: new Date(2000, 1, 1),
      },
      fromTimeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'h:i K',
      },
      toTimeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'h:i K',
      },
      blockFromTimeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'h:i K',
      },
      blockToTimeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'h:i K',
      },
      showWeeks: false,
      vacationConfigs: [],
      skills: [],
      status: [],
      employees: [],
      durations: [],
      frequencies: [],
      weekDays: [],
      weekends: [],
      holidays: [],
      comTypes: [],
    }
  },
  mounted() {
  },
  created() {
    Interviewers.getInterviewerById(this, this.$store.state.profile.interviewer_id)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          this.addInterviewer.start_date = Constants.getEprmMonthDateFromString(this.start_date)
          this.addInterviewer.end_date = Constants.getEprmMonthDateFromString(this.end_date)
          for (let index = 0; index < this.addInterviewer.vacations.length; index += 1) {
            this.addInterviewer.vacations[index].vacation_start_date = Constants.getEprmMonthDateFromString(this.addInterviewer.vacations[index].vacation_start_date_text)
            this.addInterviewer.vacations[index].vacation_end_date = Constants.getEprmMonthDateFromString(this.addInterviewer.vacations[index].vacation_end_date_text)
          }
          Interviewers.updateInterviewer(this, this.$store.state.profile.interviewer_id, this.addInterviewer)
        }
      })
    },
    setInterviewer(interviewer) {
      LIST.getINterviewerDurationFullList(this)
      LIST.getStatusFullList(this, 'HR', 'EM')
      LIST.getWeekDaysFullList(this)
      LIST.getFrequencyFullList(this)
      LIST.getHolidaysFullList(this)
      LIST.getSkillsFullList(this)
      LIST.getCommunicationTypesFullList(this)
      this.addInterviewer = interviewer
      if (this.recurring === null) {
        this.recurring = {}
      }
      this.recurring.id = interviewer.recurring_id
      this.recurring.label = interviewer.recurring
      if (this.addInterviewer.available_start_time !== null) {
        this.$set(this.toTimeConfig, 'minTime', moment(this.addInterviewer.available_start_time, 'hh:mm a').format('HH:mm'))
        this.$set(this.blockFromTimeConfig, 'minTime', moment(this.addInterviewer.available_start_time, 'hh:mm a').format('HH:mm'))
      }
      if (this.addInterviewer.available_end_time !== null) {
        this.$set(this.fromTimeConfig, 'maxTime', moment(this.addInterviewer.available_end_time, 'hh:mm a').format('HH:mm'))
        this.$set(this.blockToTimeConfig, 'maxTime', moment(this.addInterviewer.available_end_time, 'hh:mm a').format('HH:mm'))
      }
      if (this.addInterviewer.block_start_time !== null) {
        this.$set(this.blockToTimeConfig, 'minTime', moment(this.addInterviewer.block_start_time, 'hh:mm a').format('HH:mm'))
      }
      if (this.addInterviewer.block_end_time !== null) {
        this.$set(this.blockFromTimeConfig, 'maxTime', moment(this.addInterviewer.block_end_time, 'hh:mm a').format('HH:mm'))
      }
      if (this.addInterviewer.start_date !== null) {
        this.start_date = this.addInterviewer.start_date
        this.$set(this.endDateConfig, 'minDate', new Date(moment(this.start_date).format('MM-DD-YYYY')))
      }
      if (this.addInterviewer.end_date !== null) {
        this.end_date = this.addInterviewer.end_date
        this.$set(this.startDateConfig, 'maxDate', new Date(moment(this.end_date).format('MM-DD-YYYY')))
      }

      for (let index = 0; index < this.addInterviewer.vacations.length; index += 1) {
        const vacationConfig = {
          from: {
            wrap: true, // set wrap to true only when using 'input-group'
            altFormat: 'm-d-Y',
            altInput: true,
            dateFormat: 'm-d-Y',
            minDate: new Date(2000, 1, 1),
          },
          to: {
            wrap: true, // set wrap to true only when using 'input-group'
            altFormat: 'm-d-Y',
            altInput: true,
            dateFormat: 'm-d-Y',
            minDate: new Date(2000, 1, 1),
          },
          fromTime: {
            enableTime: true, noCalendar: true, dateFormat: 'h:i K',
          },
          toTime: {
            enableTime: true, noCalendar: true, dateFormat: 'h:i K',
          },
        }
        this.vacationConfigs.push(vacationConfig)
        this.$set(this.vacationConfigs[index].to, 'minDate', new Date(moment(this.addInterviewer.vacations[index].vacation_start_date_text).format('MM-DD-YYYY')))
        if (this.addInterviewer.vacations[index].vacation_start_time !== null) {
          this.$set(this.vacationConfigs[index].toTime, 'minTime', moment(this.addInterviewer.vacations[index].vacation_start_time, 'hh:mm a').format('HH:mm'))
        }
        if (this.addInterviewer.vacations[index].vacation_end_time !== null) {
          this.$set(this.vacationConfigs[index].fromTime, 'maxTime', moment(this.addInterviewer.vacations[index].vacation_end_time, 'hh:mm a').format('HH:mm'))
        }
        this.$set(this.vacationConfigs[index].from, 'maxDate', new Date(moment(this.addInterviewer.vacations[index].vacation_end_date_text).format('MM-DD-YYYY')))
      }
    },
    frequencyChanged() {
      this.addInterviewer.recurring_id = this.recurring.id
      this.showWeeks = this.recurring.show_weeks
    },
    employeeChanged() {
      this.addInterviewer.employee_id = this.existing_user.id
      this.addInterviewer.email = this.existing_user.email
      this.addInterviewer.name = this.existing_user.label
    },
    addVacation() {
      const vacationInfo = {
        vacation_start_date_text: null,
        vacation_end_date_text: null,
        vacation_start_time: null,
        vacation_end_time: null,
        is_all_day: false,
      }
      this.addInterviewer.vacations.push(vacationInfo)
      const vacationConfig = {
        from: {
          wrap: true, // set wrap to true only when using 'input-group'
          altFormat: 'm-d-Y',
          altInput: true,
          dateFormat: 'm-d-Y',
          minDate: new Date(2000, 1, 1),
        },
        to: {
          wrap: true, // set wrap to true only when using 'input-group'
          altFormat: 'm-d-Y',
          altInput: true,
          dateFormat: 'm-d-Y',
          minDate: new Date(2000, 1, 1),
        },
        fromTime: {
          enableTime: true, noCalendar: true, dateFormat: 'h:i K',
        },
        toTime: {
          enableTime: true, noCalendar: true, dateFormat: 'h:i K',
        },
      }
      this.vacationConfigs.push(vacationConfig)
    },
    availableStartDateChanged() {
      this.$set(this.endDateConfig, 'minDate', new Date(moment(this.start_date).format('MM-DD-YYYY')))
    },
    availableEndDateChanged() {
      this.$set(this.startDateConfig, 'maxDate', new Date(moment(this.end_date).format('MM-DD-YYYY')))
    },
    startDateChanged(index) {
      this.$set(this.vacationConfigs[index].to, 'minDate', new Date(moment(this.addInterviewer.vacations[index].vacation_start_date_text).format('MM-DD-YYYY')))
    },
    startTimeChanged(index) {
      this.$set(this.vacationConfigs[index].toTime, 'minTime', moment(this.addInterviewer.vacations[index].vacation_start_time, 'hh:mm a').format('HH:mm'))
    },
    endTimeChanged(index) {
      this.$set(this.vacationConfigs[index].fromTime, 'maxTime', moment(this.addInterviewer.vacations[index].vacation_end_time, 'hh:mm a').format('HH:mm'))
    },
    endDateChanged(index) {
      this.$set(this.vacationConfigs[index].from, 'maxDate', new Date(moment(this.addInterviewer.vacations[index].vacation_end_date_text).format('MM-DD-YYYY')))
    },
    availableStartTimeChanged() {
      this.$set(this.toTimeConfig, 'minTime', moment(this.addInterviewer.available_start_time, 'hh:mm a').format('HH:mm'))
      this.$set(this.blockToTimeConfig, 'minTime', moment(this.addInterviewer.available_start_time, 'hh:mm a').format('HH:mm'))
      this.$set(this.blockFromTimeConfig, 'minTime', moment(this.addInterviewer.available_start_time, 'hh:mm a').format('HH:mm'))
    },
    availableEndTimeChanged() {
      this.$set(this.fromTimeConfig, 'maxTime', moment(this.addInterviewer.available_end_time, 'hh:mm a').format('HH:mm'))
      this.$set(this.blockToTimeConfig, 'maxTime', moment(this.addInterviewer.available_end_time, 'hh:mm a').format('HH:mm'))
      this.$set(this.blockFromTimeConfig, 'maxTime', moment(this.addInterviewer.available_end_time, 'hh:mm a').format('HH:mm'))
    },
    blockStartTimeChanged() {
      this.$set(this.blockToTimeConfig, 'minTime', moment(this.addInterviewer.block_start_time, 'hh:mm a').format('HH:mm'))
    },
    blockEndTimeChanged() {
      this.$set(this.blockFromTimeConfig, 'maxTime', moment(this.addInterviewer.block_end_time, 'hh:mm a').format('HH:mm'))
    },
    removeVacation(index) {
      this.addInterviewer.vacations.splice(index, 1)
      this.vacationConfigs.splice(index, 1)
    },
    setEmployees(employee) {
      this.employees = employee
    },
    setStatus(status) {
      this.status = status
    },
    setDuration(duration) {
      this.durations = duration
    },
    setFrequencies(frequencies) {
      this.frequencies = frequencies
    },
    setWeekDays(days) {
      this.weekDays = days
    },
    setHolidays(days) {
      this.holidays = days
    },
    setCommunicationTypes(types) {
      this.comTypes = types
    },
    setSkills(skills) {
      this.skills = skills
    },
    addCommunication() {
      const communicationType = {
        type: null,
        typeId: null,
        value: '',
      }
      this.addInterviewer.communications.push(communicationType)
    },
    removeCommunication(index) {
      this.addInterviewer.communications.splice(index, 1)
    },
    technologySelected(id) {
      return this.addInterviewer.skill_ids.includes(id)
    },
    checked(id) {
      if (this.addInterviewer.skill_ids.includes(id)) {
        this.addInterviewer.skill_ids.splice(this.addInterviewer.skill_ids.indexOf(id), 1)
      } else {
        this.addInterviewer.skill_ids.push(id)
      }
    },
    removeTechnology(index) {
      this.addInterviewer.skill_ids.splice(index, 1)
    },
    interviewerUpdated(data) {
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    interviewerNotUpdated(message) {
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    communicationTypeMatch(typeId, index) {
      if (this.addInterviewer.communications.filter(communicationType => communicationType.type_id === typeId).length > 1) {
        this.addInterviewer.communications[index].type_id = null
      }
    },
    back() {
      this.$router.push('/my-interviews').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
